@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
 


* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  min-height: 100%;
  margin: 0;
}

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 22px;
  margin: 0;
}

main {
  display: block;
}

img {
  border: 0;
  max-width: 100%;
}

p {
  margin: 18px 0;
}
p:last-child {
  margin: 0;
}

ul, ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a, input, textarea {
  outline: none;
}

a {
  color: #005bbb;
}

a:hover {
  text-decoration: none;
}

th {
  font-weight: normal;
  text-align: left;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
  overflow: auto;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.clear {
  clear: both;
  display: block;
}

hr {
  background: #eee;
  height: 1px;
  border: 0;
  padding: 0;
  margin: 24px 0;
}

/* === Responsive grid === */
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.container {
  margin: 0 auto;
  width: 1160px;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

[class*=grid] {
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}

.grid20 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.grid2 {
  -ms-flex: 0 0 16.666666%;
  flex: 0 0 16.666666%;
  max-width: 16.666666%;
}

.grid3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.grid4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.grid5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.grid6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.grid7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.grid8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.grid9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.grid10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.xs-visible, .sm-visible, .tb-visible, .lg-visible {
  display: none !important;
}

h2, .pagetitle {
  color: #2d2d2d;
  margin: 0 0 24px;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 28px;
  line-height: 32px;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;
}

/* === FORMS === */
.form-control, .btn {
  font-family: "Roboto", sans-serif;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  outline: none;
}

/* === Text field === */
.form-control {
  background: 0;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 10px 14px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Roboto", sans-serif;
  height: 44px;
}
.form-control:focus {
  border-color: #2d2d2d;
}
.form-control.error {
  border-color: #005bbb;
}

.form-line {
  margin: 0 0 20px;
}
.form-line .form-control {
  max-width: 400px;
  width: 100%;
}
.form-line textarea.form-control {
  width: 100%;
  height: 90px;
  resize: none;
  max-width: 720px;
}

/* === Button === */
.btn {
  background: #005bbb;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  border: 0;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 30px;
  text-transform: uppercase;
  transition: all 200me ease-in-out;
}
.btn-border {
  background: #FFD500;
  border: 1px solid #2d2d2d;
  color: #000;
}
.btn-border:hover {
  background: rgb(200, 191, 153);
}

input.btn, button.btn {
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  line-height: 40px;
}

/* language */
.language {
  position: relative;
}

.language li {
  margin: 0;
  display: block;
}

.language-item-active:hover {
  background-color: #aab4bf;
}

.language-item {
  position: relative;
  padding: 10px 15px 10px 45px;
  display: block;
  color: #000;
  text-decoration: none;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}
.language-item::before {
  content: "";
  position: absolute;
  display: block;
  left: 15px;
  margin-top: -9px;
  top: 50%;
  width: 22px;
  height: 18px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.language-btn {
  padding: 0 12px 0 30px;
}
.language-btn::before {
  left: 0;
}
.language-btn::after {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  top: 5px;
  border: 4px solid transparent;
  border-top: 4px solid #000;
}
.language-dropdown {
  position: absolute;
  right: 0;
  background: #fff;
  margin: 0;
  padding: 0;
  display: block;
  margin-top: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease-in-out;
}
.language-ua::before {
  background-image: url(../images/ukraine.svg);
}
.language-en::before {
  background-image: url(../images/uk.svg);
}
.language-de::before {
  background-image: url(../images/germany.svg);
}
.language-ru::before {
  background-image: url(../images/russian.svg);
}
.language-pl::before {
  background-image: url(../images/poland.svg);
}
.language.opened .language-btn::after {
  transform: matrix(1, 0, 0, -1, 0, 0);
  top: 2px;
}
.language.opened .language-dropdown {
  opacity: 1;
  visibility: visible;
}

/* btn-menu */
.btn-menu {
  background: url(../images/bars.svg) center center/30px no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
}

body.nav-opened .btn-menu {
  background-image: url(../images/close.svg);
  z-index: 1010;
}

/* Header */
.header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 20px 0 0;
}
.header-top {
  min-height: 200px;
}
.header-top > .container {
  display: flex;
}
.header .container {
  position: relative;
  z-index: 20;
}
.header-shadow {
  background: white;
  background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$from", endColorstr="$to",GradientType=0 );
  height: 180px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.header-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
  bottom: -1px;
}
.header-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
}
.header-shape svg path {
  fill: #fff;
}
.header-title {
  background: rgba(0, 0, 0, 0.35);
  padding: 16px 0;
  min-height: 130px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-title h1 {
  text-transform: uppercase;
  font-family: 24px;
  line-height: 26px;
  color: #fff;
  font-weight: 500;
}
.header-logo {
  min-width: 240px;
  max-width: 240px;
  margin-right: 20px;
}
.header-logo img {
  display: block;
}
.header-column {
  flex: 1;
  text-align: right;
}
.header-column__top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 20px;
}
.header-column .language {
  margin-left: 30px;
}
.header .btn-menu {
  display: none;
  margin-left: 30px;
}
.header .btn.sm-visible {
  margin: 16px 16px 0;
  display: none;
}

/* navmenu */
.navmenu ul {
  display: flex;
  justify-content: flex-end;
}
.navmenu li {
  font-size: 16px;
  line-height: 20px;
  margin: 0 30px 0 0;
  font-weight: 500;
  text-transform: uppercase;
}
.navmenu li:last-child {
  margin: 0;
}
.navmenu li a {
  color: #000;
  text-decoration: none;
  transition: all 200ms ease-in-out;
}
.navmenu li a:hover {
  color: #005bbb;
}

/* Footer */
.footer {
  background: #2C2C2C;
  color: #fff;
}
.footer-top {
  height: 90px;
  
  
}

 

.footer-middle {
  padding: 30px 0;
  border-top: 1px solid #5c500f;
}
.footer-title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 20px;
}
.footer a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.footer a:hover {
  color: #fff;
}
.footer .logo {
  margin: 0 0 12px;
  max-width: 240px;
}
.footer p {
  margin: 12px 0;
}
.footer ul li {
  margin: 0 0 4px;
}

.copyright {
  background: #000;
  border-top: 1px solid #5c500f;
  color: #fff;
  padding: 20px 0;
}

/* main-section */
.main-section {
  padding: 40px 0;
}

/* article */
.article {
  margin-bottom: 32px;
}
.article ul, .article ol {
  margin: 18px 0 18px 20px;
}
.article li {
  margin-bottom: 8px;
}
.article ul li {
  list-style: outside disc;
}
.article ol li {
  list-style: outside decimal;
}

/* cardbox */
.cardbox {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  min-height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.cardbox-row [class*=grid] {
  margin-bottom: 24px;
}
.cardbox-img {
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}
.cardbox-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
}
.cardbox-top {
  flex: 1;
}
.cardbox-title {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 22px;
  line-height: 26px;
  margin: 0 0 12px;
  color: #2d2d2d;
}
.cardbox-text {
  color: #9a9a9a;
  margin: 0 0 20px;
  font-weight: 300;
}
.cardbox-more {
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
}

/* spoiler */
.spoiler {
  
  border-radius: 6px;
  margin: 0 0 20px;
}
.spoiler-name {
  background: #005bbb;
  display: block;
  color: #ffffff;
  border-radius: 5px;
  padding: 14px 20px;
  padding-right: 45px;
  text-decoration: none;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
  position: relative;
}
.spoiler-name::after {
  content: "";
  position: absolute;  
  background-image: url("../images/chevron.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 16px;
  right: 14px;
  top: 50%;
  margin-top: -8px;
  transition: all 200ms ease-in-out;
}
.spoiler-hide {
  transition: height 100ms linear;
  overflow: hidden;
  height: 0;
}
.spoiler-hide__inner {
  padding: 20px;
}
.spoiler-hide p {
  margin-top: 0;
}
.spoiler.opened {
  border-color: #2d2d2d;
}
.spoiler.opened .spoiler-name {
  background: #2d2d2d;
  border-radius: 5px 5px 0 0;
  color: #ffffff;
}
.spoiler.opened .spoiler-name::after {
  transform: rotate(-180deg);
}

/* === Reset === */
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  min-height: 100%;
  margin: 0;
}

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 22px;
  margin: 0;
}

main {
  display: block;
}

img {
  border: 0;
  max-width: 100%;
}

p {
  margin: 18px 0;
}
p:last-child {
  margin: 0;
}

ul, ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a, input, textarea {
  outline: none;
}

a {
  color: #005bbb;
}

a:hover {
  text-decoration: none;
}

th {
  font-weight: normal;
  text-align: left;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
  overflow: auto;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.clear {
  clear: both;
  display: block;
}

hr {
  background: #eee;
  height: 1px;
  border: 0;
  padding: 0;
  margin: 24px 0;
}

/* === Responsive grid === */
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

.container {
  margin: 0 auto;
  width: 1160px;
}

.row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

[class*=grid] {
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}

.grid20 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.grid2 {
  -ms-flex: 0 0 16.666666%;
  flex: 0 0 16.666666%;
  max-width: 16.666666%;
}

.grid3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.grid4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.grid5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.grid6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.grid7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.grid8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.grid9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.grid10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.xs-visible, .sm-visible, .tb-visible, .lg-visible {
  display: none !important;
}

h2, .pagetitle {
  color: #2d2d2d;
  margin: 0 0 24px;
  font-weight: 700;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 28px;
  line-height: 32px;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;
}

/* === FORMS === */
.form-control, .btn {
  font-family: "Roboto", sans-serif;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  outline: none;
}

/* === Text field === */
.form-control {
  background: 0;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 10px 14px;
  font-size: 16px;
  line-height: 22px;
  font-family: "Roboto", sans-serif;
  height: 44px;
}
.form-control:focus {
  border-color: #2d2d2d;
}
.form-control.error {
  border-color: #005bbb;
}

.form-line {
  margin: 0 0 20px;
}
.form-line .form-control {
  max-width: 400px;
  width: 100%;
}
.form-line textarea.form-control {
  width: 100%;
  height: 90px;
  resize: none;
  max-width: 720px;
}

/* === Button === */
.btn {
  background: #005bbb;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  border: 0;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 30px;
  text-transform: uppercase;
  transition: all 200me ease-in-out;
}
.btn-border {
  background: #FFD500;
  border: 1px solid #2d2d2d;
  color: #000;
}
.btn-border:hover {
  background: rgb(200, 191, 153);
}

input.btn, button.btn {
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  line-height: 40px;
}

/* language */
.language {
  position: relative;
}
.language li {
  margin: 0;
  display: block;
}
.language-item {
  position: relative;
  padding: 10px 15px 10px 45px;
  display: block;
  color: #000;
  text-decoration: none;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}
.language-item::before {
  content: "";
  position: absolute;
  display: block;
  left: 15px;
  margin-top: -9px;
  top: 50%;
  width: 22px;
  height: 18px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.language-btn {
  padding: 0 12px 0 30px;
}
.language-btn::before {
  left: 0;
}
.language-btn::after {
  content: "";
  position: absolute;
  display: block;
  right: 0;
  top: 5px;
  border: 4px solid transparent;
  border-top: 4px solid #000;
}
.language-dropdown {
  position: absolute;
  right: 0;
  background: #fff;
  margin: 0;
  padding: 0;
  display: block;
  margin-top: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease-in-out;
}
.language-ua::before {
  background-image: url(../images/ukraine.svg);
}
.language-en::before {
  background-image: url(../images/uk.svg);
}
.language-de::before {
  background-image: url(../images/germany.svg);
}
.language-ru::before {
  background-image: url(../images/russian.svg);
}
.language-pl::before {
  background-image: url(../images/poland.svg);
}
.language.opened .language-btn::after {
  transform: matrix(1, 0, 0, -1, 0, 0);
  top: 2px;
}
.language.opened .language-dropdown {
  opacity: 1;
  visibility: visible;
}

/* btn-menu */
.btn-menu {
  background: url(../images/bars.svg) center center/30px no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
}

body.nav-opened .btn-menu {
  background-image: url(../images/close.svg);
  z-index: 1010;
}

.instabtn {
  background-image: url("../images/instagram.svg");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 20px;  
}

/* medium screen */
@media screen and (max-width: 1200px) {
  .container {
    width: 992px;
  }

  .lg4 {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .lg6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .lg12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .lg-hidden {
    display: none !important;
  }

  .lg-visible {
    display: block !important;
  }

  .lg-flex {
    display: flex !important;
  }
}
/* tablet screen */
@media screen and (max-width: 1020px) {
  .container {
    width: 720px;
  }

  .tb4 {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .tb6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .tb12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tb-hidden {
    display: none !important;
  }

  .tb-visible {
    display: block !important;
  }

  .tb-flex {
    display: flex !important;
  }

  /* header */
  .header .btn-menu {
    display: block;
  }

  .header .container {
    align-items: center;
  }

  .header-column__top {
    margin: 0;
  }

  .navmenu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.35);
    background: #fff;
    padding: 20px 0;
    width: 240px;
    transition: all 200ms ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
  }
  .navmenu ul {
    display: block;
  }
  .navmenu li {
    text-align-last: left;
    margin: 0;
  }
  .navmenu li a {
    display: block;
    padding: 14px 20px;
  }
  .navmenu li a:hover {
    background: #005bbb;
    color: #fff;
  }

  .nav-overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.7;
    z-index: 999;
  }

  body.nav-opened {
    overflow: hidden;
    height: 100vh;
  }
  body.nav-opened .header-top {
    position: relative;
    z-index: 1000;
  }
  body.nav-opened .navmenu {
    opacity: 1;
    visibility: visible;
  }
  body.nav-opened .nav-overlay {
    display: block;
  }

  /* footer */
  .footer-info {
    margin-bottom: 24px;
    order: 2;
  }
}
/* Mobile large screen */
@media screen and (max-width: 750px) {
  .container {
    width: auto;
    margin: 0 16px;
  }

  .sm4 {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
  }

  .sm6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sm12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sm-hidden {
    display: none !important;
  }

  .sm-visible, .btn-sm-block {
    display: block !important;
  }

  .btn-sm-block {
    width: 100%;
  }

  .sm-flex {
    display: flex !important;
  }

  /* header */

  .header-top {
    z-index: 10;
    position: relative;
  }

  .header-logo {
    min-width: 160px;
    max-width: 160px;
  }

  .header .btn {
    padding: 14px 10px;
  }

  .header .btn-menu {
    margin-left: 30px;
  }

  /* footer */
  .footer .grid4 {
    margin-bottom: 20px;
  }

  .instabtn {
    margin: 0;
  }

}

/* patch */

.monobank {
  display: inline-flex !important;
  justify-content: center;
  /*background: linear-gradient(136.56deg, #8a53b6 0%, #cea2d0 97.42%);*/
  background: #FFD500;
  border: 1px solid #2d2d2d;
  color: #2d2d2d;
}

.monobank:hover {
  background: rgb(200, 191, 153);
}

.monobank img {
  margin-right: 6px;
}

.footer-info, .copyright {  
  font-size: 14px;
}

.cryptoline img {
  vertical-align: text-bottom;
}
 
.crypto-min {
  color: #821414;
}

.cryptoline input {
  width: 100%;
  background: rgb(245, 247, 248);
  border-radius: 5px;
  color: black;
  font-weight: 400;
  border: 1px solid #d1d1d1;
  line-height: 20px;
  padding: 15px;
  position: relative;
  word-break: break-all;
  height: 55px;
}
/* */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.internal_reports_img {
  text-align: center;
}
.internal_reports_img img {
  max-width: 960px;
  width: 100%;
}

.menu_header_current_page {
  color: #005bbb !important;
  border-bottom: 1px solid #005bbb;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.1);
}

.lang-soon {
  opacity: 0.2;
}

.cursor-pointer {
  cursor: pointer;
}

.BackToReports {
   
  background-image: url("../images/chevron.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 16px;
  transform: rotate(90deg);
}